/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

const AccessibilityStatement = ({
  pageContext: {
    accessibilityStatementPage: { title, body },
  },
}) => (
  <Layout title={title}>
    <div sx={{ maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`] }}>
      <Themed.h1>{title}</Themed.h1>
      <RichText render={body} />
    </div>
  </Layout>
);

export default AccessibilityStatement;
